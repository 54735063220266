import React, { createContext, useCallback, useContext, useReducer } from 'react'
import { QueryParams } from '../../../../../App'
import { Document } from '../../../../../libs/mobbscan/mobbscan.types'
import {
  DocumentList,
  Guest,
} from '../../../../../api/savetravelregister_guestapp/savetravelregister_guestapp.types'
import useQuery from '../../../../../hooks/useQuery'

export enum RegisterFormStepNames {
  collectDataForm = 'collectDataForm',
  signContract = 'signContract',
  checkinComplete = 'checkinComplete',
}
interface RegisterFormStateI {
  step: number
  guest: Guest
  photosDoc: {
    photoFront?: string
    photoBack?: string
  }
  photoFace?: string
  photoSignature?: string
  rawData?: Document
  DocumentsToSign: DocumentList[]
}

export enum RegisterFormTypes {
  ChangeStep = 'ChangeStep',
  NextStep = 'NextStep',
  PrevStep = 'PrevStep',
  UpdateGuest = 'UpdateGuest',
  SetphotoFront = 'SetphotoFront',
  SetPhotoBack = 'SetPhotoBack',
  SetPhotoSignature = 'SetPhotoSignature',
  SetRawData = 'SetRawData',
  SetPhotoFace = 'SetPhotoFace',
  SetDocumentsToSign = 'SetDocumentsToSign',
}

type RegisterFormAction =
  | {
      type: RegisterFormTypes.ChangeStep
      payload: number
    }
  | {
      type: RegisterFormTypes.NextStep
    }
  | {
      type: RegisterFormTypes.PrevStep
    }
  | {
      type: RegisterFormTypes.UpdateGuest
      payload: Partial<Guest>
    }
  | {
      type: RegisterFormTypes.SetphotoFront
      payload: string
    }
  | {
      type: RegisterFormTypes.SetPhotoBack
      payload: string
    }
  | {
      type: RegisterFormTypes.SetPhotoSignature
      payload: string
    }
  | {
      type: RegisterFormTypes.SetRawData
      payload: Document
    }
  | {
      type: RegisterFormTypes.SetPhotoFace
      payload: string
    }
  | {
      type: RegisterFormTypes.SetDocumentsToSign
      payload: DocumentList[]
    }
interface RegisterFormContextI {
  state: RegisterFormStateI
  moveForwardsSteps: () => void
  moveBackwardsSteps: () => void
  updateGuest: (guest: Partial<Guest>) => void
  resetGuest: () => void
  resetSteps: () => void
  dispatch: React.Dispatch<RegisterFormAction>
  setDocumentsToSign: (documents: DocumentList[]) => void
}

export const REGISTER_FORM_STEPS: RegisterFormStepNames[] = [
  RegisterFormStepNames.collectDataForm,
  RegisterFormStepNames.signContract,
  RegisterFormStepNames.checkinComplete,
]

const initialState: RegisterFormStateI = {
  step: 0,
  guest: {
    name: '',
    surname1: '',
    surname2: '',
    identifier: '',
    identifierTypeId: '',
    countryId: '',
    bornDate: '',
    email: '',
    sexId: '',
    userLang: '',
    address: '',
    postalCode: '',
  },
  photosDoc: {
    photoBack: undefined,
    photoFront: undefined,
  },
  photoFace: undefined,
  photoSignature: undefined,
  DocumentsToSign: [],
}

const RegisterFormContext = createContext({} as RegisterFormContextI)

const registerFormReducer = (state: RegisterFormStateI, action: RegisterFormAction) => {
  switch (action.type) {
    case RegisterFormTypes.ChangeStep: {
      return { ...state, step: action.payload }
    }
    case RegisterFormTypes.NextStep: {
      let nextStep = state.step + 1
      if (nextStep >= REGISTER_FORM_STEPS.length) nextStep = REGISTER_FORM_STEPS.length - 1
      return { ...state, step: nextStep }
    }
    case RegisterFormTypes.PrevStep: {
      let nextStep = state.step - 1
      if (nextStep < 0) nextStep = 0
      return { ...state, step: nextStep }
    }
    case RegisterFormTypes.UpdateGuest: {
      const newState = {
        ...state,
        guest: { ...state.guest, ...action.payload },
      }
      return newState
    }
    case RegisterFormTypes.SetphotoFront: {
      const newState: RegisterFormStateI = {
        ...state,
        photosDoc: { ...state.photosDoc, photoFront: action.payload },
      }
      return newState
    }

    case RegisterFormTypes.SetPhotoBack: {
      const newState: RegisterFormStateI = {
        ...state,
        photosDoc: { ...state.photosDoc, photoBack: action.payload },
      }
      return newState
    }

    case RegisterFormTypes.SetPhotoFace: {
      const newState: RegisterFormStateI = {
        ...state,
        photoFace: action.payload,
      }
      return newState
    }

    case RegisterFormTypes.SetPhotoSignature: {
      const newState: RegisterFormStateI = {
        ...state,
        photoSignature: action.payload,
      }
      return newState
    }

    case RegisterFormTypes.SetRawData: {
      const newState: RegisterFormStateI = {
        ...state,
        rawData: action.payload,
      }
      return newState
    }

    case RegisterFormTypes.SetDocumentsToSign: {
      const newState: RegisterFormStateI = {
        ...state,
        DocumentsToSign: action.payload,
      }
      return newState
    }

    default: {
      throw new Error(`Unhandled action type: ${(action as any).type}`)
    }
  }
}

export const RegisterFormProvider: React.FC = (props) => {
  const { queryParams } = useQuery()
  const [state, dispatch] = useReducer(registerFormReducer, {
    ...initialState,
    guest: {
      ...initialState.guest,
      userLang: queryParams?.[QueryParams.LangId] || 'es',
    },
  })

  const resetSteps = useCallback(() => {
    dispatch({
      type: RegisterFormTypes.ChangeStep,
      payload: 0,
    })
  }, [])

  const moveForwardsSteps = useCallback(() => {
    dispatch({ type: RegisterFormTypes.NextStep })
  }, [state.step])

  const moveBackwardsSteps = useCallback(() => {
    dispatch({ type: RegisterFormTypes.PrevStep })
  }, [state.step])

  const updateGuest = useCallback(
    (guest: Partial<Guest>) => {
      dispatch({
        type: RegisterFormTypes.UpdateGuest,
        payload: { ...state.guest, ...guest },
      })
    },
    [state.guest]
  )

  const resetGuest = useCallback(() => {
    dispatch({
      type: RegisterFormTypes.UpdateGuest,
      payload: {
        ...initialState.guest,
        userLang: state.guest.userLang,
      },
    })
  }, [state.guest])

  const setDocumentsToSign = useCallback(
    (documents: DocumentList[]) => {
      dispatch({
        type: RegisterFormTypes.SetDocumentsToSign,
        payload: documents,
      })
    },
    [state.DocumentsToSign]
  )

  return (
    <RegisterFormContext.Provider
      value={{
        state,
        moveForwardsSteps,
        moveBackwardsSteps,
        updateGuest,
        resetGuest,
        resetSteps,
        dispatch,
        setDocumentsToSign,
      }}
    >
      {props.children}
    </RegisterFormContext.Provider>
  )
}

export const useRegisterFormContext = () => {
  const context = useContext(RegisterFormContext)
  if (context === undefined) {
    throw new Error('This hook can only be called inside the RegisterFormProvider')
  }
  return context
}
