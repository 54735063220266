import React, { createContext, useCallback, useContext, useEffect, useReducer } from 'react'
import { apiFindReserveGuestApp } from '../api/findreserve_guestapp/findreserve_guestapp'
import { ApiReserveDataGuestAppResponse } from '../api/findreserve_guestapp/findreserve_guestapp.types'
import { QueryParams } from '../App'
import useQuery from '../hooks/useQuery'
import * as Sentry from '@sentry/react'
import { logger } from '../utils/logger.util'
import { API_LOCALE_MAP, useLanguageContext } from './LanguageContext'

interface Props {}

type CheckinErrorI =
  | {
      title?: string
      description?: string
      hideTryAgainButton?: boolean
    }
  | undefined

export enum ApiDataErrorType {
  NoGuestCode = 'NoGuestCode',
  ServerError = 'ServerError',
}

type ErrorI =
  | {
      title?: string
      description?: string
      type: ApiDataErrorType
    }
  | undefined

type ApiDataContextAction =
  | {
      type: 'setInitialData'
      payload: {
        apiData: ApiReserveDataGuestAppResponse
      }
    }
  | { type: 'setApiData'; payload: ApiReserveDataGuestAppResponse }
  | { type: 'setLoading'; payload: boolean }
  | {
      type: 'setError'
      payload: ErrorI
    }
  | {
      type: 'setCheckinError'
      payload: CheckinErrorI
    }
  | {
      type: 'setHaveExtras'
      payload: boolean
    }

export interface ApiDataState {
  apiData: ApiReserveDataGuestAppResponse | undefined
  loading: boolean
  error: ErrorI
  checkinError: CheckinErrorI
  haveExtras?: boolean | undefined
}

interface ApiDataContextI {
  state: ApiDataState
  dispatch: React.Dispatch<ApiDataContextAction>
  methods: {
    fetchApiData: () => Promise<void>
  }
  restAdultsToRegister: number
  spainId?: string
}

export const ApiDataContext = createContext({} as ApiDataContextI)

const initialState: ApiDataState = {
  apiData: undefined,
  error: undefined,
  checkinError: undefined,
  loading: true,
}

const apiDataReducer = (state: ApiDataState, action: ApiDataContextAction): ApiDataState => {
  switch (action.type) {
    case 'setInitialData':
      return {
        ...state,
        loading: false,
        apiData: action.payload.apiData,
      }
    case 'setApiData':
      return {
        ...state,
        apiData: action.payload,
      }
    case 'setLoading':
      return { ...state, loading: action.payload }
    case 'setError':
      return { ...state, loading: false, error: action.payload }
    case 'setCheckinError':
      return { ...state, loading: false, checkinError: action.payload }
    case 'setHaveExtras':
      return { ...state, haveExtras: action.payload }
    default:
      throw new Error(`Unhandled action type: ${(action as any).type}`)
  }
}

export const ApiDataContextProvider: React.FC<Props> = (props) => {
  const [state, dispatch] = useReducer(apiDataReducer, initialState)

  const { queryParams } = useQuery()
  const { language } = useLanguageContext()

  const fetchApiData = useCallback(async () => {
    const guestCode = queryParams?.[QueryParams.GuestCode]!

    dispatch({ type: 'setLoading', payload: true })
    try {
      const apiDataResponse = await apiFindReserveGuestApp({
        guestCode,
        userLang: API_LOCALE_MAP[language],
      })
      dispatch({
        type: 'setInitialData',
        payload: {
          apiData: apiDataResponse,
        },
      })
    } catch (error: any) {
      logger.logError({
        message: 'Error on ApiDataContext.tsx fetchApiData function',
        error,
      })
      dispatch({
        type: 'setError',
        payload: {
          title:
            error.status === 'NOT_FOUND'
              ? 't:error.guestCodeNotFound'
              : 't:error.anErrorHasOcurred',
          type: ApiDataErrorType.ServerError,
        },
      })
    }
  }, [language])

  // useEffect(() => {
  //   if (!queryParams) return;

  //   if (!guestCode) {
  //     return dispatch({
  //       type: "setError",
  //       payload: {
  //         title: "t:error.noGuestCodeProvided",
  //         hideTryAgainButton: true
  //       }
  //     });
  //   }

  //   fetchApiData();
  // }, [fetchApiData, queryParams, dispatch, guestCode]);

  useEffect(() => {
    if (state.apiData?.guestCode) Sentry.setTag('guestCode', state.apiData.guestCode)
  }, [state.apiData?.guestCode])

  return (
    <ApiDataContext.Provider
      value={{
        state,
        dispatch,
        methods: {
          fetchApiData,
        },
        restAdultsToRegister:
          Number(state.apiData?.adults ?? 0) - (state.apiData?.travelRegisteredList.length ?? 0),
        spainId: state.apiData?.countryDataList.find((country) => country.isoCountry === 'ES')!.id,
      }}
    >
      {props.children}
    </ApiDataContext.Provider>
  )
}

export const useApiDataContext = () => {
  const context = useContext(ApiDataContext)
  if (context === undefined) {
    throw new Error('useApiDataContext must be called inside ApiDataContextProvider')
  }
  return context
}
