import { Box, Button, Checkbox, InputBase, Modal, Typography } from '@mui/material'
import React, { Suspense, useEffect, useRef, useState } from 'react'
import ErrorComponent from './components/error/Error'
import FullscreenLoading from './components/fullscreenLoading/FullscreenLoading'
import Loading from './components/loading/Loading'
import { useApiDataContext } from './contexts/ApiDataContext'
import useQuery from './hooks/useQuery'
import { t } from 'i18next'
import homeIcon from './assets/icons/house.svg'
import parseHtml from 'html-react-parser'
import { Helmet } from 'react-helmet-async'
// Toastify
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Router from './router/Router'
import HoverLoadingBlur from './components/hoverLoadingBlur/HoverLoadingBlur'
import LanguageSelector from './components/languageSelector/LanguageSelector'
import { useLanguageContext } from './contexts/LanguageContext'

export enum QueryParams {
  GuestCode = 'guestcode',
  LangId = 'langid',
  Device = 'device',
  ForceDefaultPage = 'forcedefaultpage',
}

export interface AppQueryParams {
  [QueryParams.GuestCode]: string
  [QueryParams.LangId]: string
  [QueryParams.Device]: string
  [QueryParams.ForceDefaultPage]: string
}

export enum Devices {
  IOS = 'ios',
  Android = 'android',
  Desktop = 'desktop',
}

const App: React.FC = () => {
  const { queryParams } = useQuery()
  const {
    state: { error: apiDataError, loading: fetchApiDataLoading, apiData },
    methods: { fetchApiData },
  } = useApiDataContext()
  const { language } = useLanguageContext()

  const [showGuestCodeForm, setShowGuestCodeForm] = useState(false)
  const [error, setError] = useState(false)
  const [checked, setChecked] = useState(false)
  const [showConditionsModal, setShowConditionsModal] = useState(false)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked
    if (newValue) setError(false)
    setChecked(event.target.checked)
  }

  const handleOpenConditionsModal = () => {
    setShowConditionsModal(true)
  }

  const handleCloseConditionsModal = () => {
    setShowConditionsModal(false)
  }

  const reserveCodeInput = useRef<HTMLInputElement>()

  useEffect(() => {
    if (!queryParams) return
    const guestCode = queryParams[QueryParams.GuestCode]
    if (!guestCode) return setShowGuestCodeForm(true)
    fetchApiData()
  }, [queryParams, language])

  if (apiDataError) {
    return (
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          display: 'grid',
          placeItems: 'center',
        }}
      >
        <ErrorComponent title={apiDataError.title} description={apiDataError.description}>
          <Button
            onClick={() => {
              window.location.replace('/')
            }}
            variant="contained"
          >
            {t('labels.back')}
          </Button>
        </ErrorComponent>
      </Box>
    )
  }

  if (showGuestCodeForm) {
    const updateGuestCode = () => {
      const searchParams = new URLSearchParams(window.location.search)
      searchParams.set('guestcode', reserveCodeInput.current!.value.trim())
      window.location.href = `/?${searchParams.toString()}`
    }

    return (
      <form
        onSubmit={(e) => {
          e.preventDefault()
          if (!checked) return setError(true)
          updateGuestCode()
          setShowGuestCodeForm(false)
        }}
        style={{
          height: '100vh',
          padding: '2rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '2rem',
        }}
      >
        <LanguageSelector />
        <img src={homeIcon} alt="" />
        <Typography sx={{ fontSize: '1.125rem', fontWeight: '600' }}>
          {t('guestCodeForm.title')}
        </Typography>
        <Typography sx={{ fontSize: '1.125rem' }}>{t('guestCodeForm.subtitle')}</Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'white',
            borderRadius: '4px',
          }}
        >
          <InputBase
            inputRef={reserveCodeInput}
            sx={{ padding: '0.75rem 1.5rem' }}
            placeholder={t('guestCodeForm.placeholder')}
          />
          <Button
            type="submit"
            variant="contained"
            sx={{
              height: '100%',
              borderTopLeftRadius: '0',
              borderBottomLeftRadius: '0',
            }}
          >
            {t('guestCodeForm.enter')}
          </Button>
        </Box>
        <Box>
          {error ? (
            <Typography sx={{ fontSize: '0.75rem', color: 'red' }}>
              {t('preview.labels.modifyBookingView.consultSuccess.error')}
            </Typography>
          ) : null}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox checked={checked} onChange={handleChange} id="conditionCheckbox" />
            <Typography sx={{ userSelect: 'none', whiteSpace: 'nowrap' }}>
              <label htmlFor="conditionCheckbox">
                {t('preview.labels.modifyBookingView.consultSuccess.conditionsTag1')}
              </label>
            </Typography>
            &nbsp;
            <Typography
              onClick={handleOpenConditionsModal}
              sx={{
                userSelect: 'none',
                cursor: 'pointer',
                whiteSpace: 'nowrap',
                '& .blue': {
                  color: '#0e82c5',
                },
              }}
            >
              {parseHtml(t('preview.labels.modifyBookingView.consultSuccess.conditionsTag2'))}
            </Typography>
          </Box>
        </Box>
        <Modal open={showConditionsModal} onClose={handleCloseConditionsModal}>
          <Box
            sx={{
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              maxHeight: '90vh',
              maxWidth: '80vw',
              overflowY: 'scroll',
              backgroundColor: '#fff',
              borderRadius: '.5rem',
              boxShadow: 24,
              p: 4,
              outline: 'none',
            }}
          >
            {t('guestCodeForm.conditions')}
          </Box>
        </Modal>
      </form>
    )
  }

  if (fetchApiDataLoading && !apiData) {
    return (
      <Box sx={{ width: '100vw', height: '100vh' }}>
        <Loading />
      </Box>
    )
  }

  return (
    <>
      <Box sx={{ minHeight: '100vh' }}>
        <Suspense fallback={<FullscreenLoading />}>
          <Helmet>
            <title>{apiData?.roomName}</title>
          </Helmet>
          <Router />
        </Suspense>
      </Box>
      <ToastContainer theme="colored" position="top-right" autoClose={3000} hideProgressBar />
      {fetchApiDataLoading && <HoverLoadingBlur />}
    </>
  )
}

export default App
